@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

body {
  --sb-track-color: #ffdbc7;
  --sb-thumb-color: #f2682a;
  --sb-size: 8px;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 4px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

/* Background Image */
.bgCotainerImage,
.bgCotainerImageAbout {
  background-image: url("/src/images/bg-container-cargo-ship-sea.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.bgCotainerImage {
  height: 300px;
}
.bgCotainerImageAbout {
  height: 160px;
}
.bgImageAboutUs {
  background-image: url("/src/images/bgAbout.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 680px;
}
.bgImageTracking {
  background-image: url("/src/images/mapBg.svg"), url("/src/images/mapBg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: scroll;
}
@media screen and (min-width: 1024px) {
  .bgImageHome {
    background-image: url("/src/images/homeBg.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
}

/* Open-Sans font family */
.openSans {
  font-family: "Open Sans", sans-serif;
}
.poppin {
  font-family: "Poppins";
}
/* Linear Orange bg */
.linearOrange {
  background: linear-gradient(90deg, #f12711 5.25%, #f5af19 91.75%);
}
/* Hamburger  */
.open {
  display: block;
}
